.foot {
  z-index: 1000;
    /* Set position to absolute to position the footer relative to the body */
  position: absolute;
    /* Set a fixed height for the footer */
  height: 218px ;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(12, 51, 102, 0.74) !important;
}

.contactfooter {
  text-align: left;
}

 p {
  font-weight: 600 !important;
}

.policies {
  font-size: 13px;
}

.policies span {
  font-size: 13px;
}
.policies a{
  text-decoration: none;
  color:aliceblue
}

.footertop {
  display: flex;
  justify-content: space-around;
  padding: 8px;
}

.credits {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.credits a{
  text-decoration: none;
  color:aliceblue;
  font-size: 14px;
}

.socials {
  text-align: left !important;
}

.Newsletter {
  display: flex;
  flex-direction: column;
}

.input-email {
  padding: 6px;
  font-size: 12px;
  border: 1px solid grey;
  border-radius: 4px;
  margin-right: 16px;
  }


.subscribeButton {
  background-color: #67757c;
  display: inline;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.foot h5{
  text-decoration: underline;
 }



@media screen and (max-width: 768px) {
  .footertop {
    flex-direction: column;
  }
  .foot{
    height: 430px ;
  }

}
