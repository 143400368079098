.quicklinks {
    border: 1px solid rgb(223, 223, 165);
    background-color: beige;
    width: 50vw;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
    
}

.finances {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    background-color: rgb(243,246,230);
    box-shadow: 1px 1px 4px 2px rgb(156, 167, 167);
    margin: 5px;
}

.financialgif{
    margin: 4px;

}

.finances article{
    font-weight: 800;

}
.saccoLoans {
    width: 48vw;
    margin: 2px;
}
.cards h5{
    display: flex;
    font-weight: 700;
    color: rgb(20, 107, 100);
    padding: 5px;
    align-items: center;
}
.saccoLoans h4, .finances h4 {
    text-align: center;
    color: rgb(12, 96, 102, 0.94);
    font-weight: 700;
}

.cards img {
    object-fit: fill;
}

.gallery, .by_laws, .downloads, .faqs, .career{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.quicklinks_row1, .quicklinks_row2, .quicklinksSacco_container{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    flex-wrap: nowrap;
}
.quicklinks h4 {
    text-align: center;
    color: rgb(12, 96, 102, 0.94);
    font-weight: 700;
}

.quicklinks a {
    text-align: center;
    text-decoration: none;
    color:darkslategrey
}

.cards button,
.saccoLoans button {
    /*border: none;
    border-radius: 4px;
    background-color: rgba(102,63,12, 0.74);
    cursor: pointer;
    padding: 8px;
    width: 100%;*/
    width:100%;
    border: none;
    border-radius: 4px;
    box-shadow: 3px 2px 3px 2px rgb(156, 167, 167);
}
.cards a,
.saccoLoans a{
    text-decoration: none;
    color: black;
    
}
.featured {
    text-align: center;
    color: rgb(12, 96, 102, 0.94);
}

.featured h4 {
    font-weight: 700;
}

.cards {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin: 3px;
    flex-wrap: nowrap;
}
.image {
    width: 100%;
    height: 60vh;
    object-fit: cover;
}

.man {
    position: absolute;
    left: 100px;
    z-index: 4000;
}


.home {
    background-color: rgb(241, 244, 244);
}
.aright{
    transform: rotate(85deg);
    margin-right: 3px;
    color:rgb(22, 128, 92);
}
.aleft{
    color:rgb(22, 128, 92)
}
.linksq{
    display: flex;
    justify-content: space-between;
}
.linksq div {
    font-size: 40px;
}

@media screen and (max-width: 768px) {
    .quicklinks, .saccoLoans{
        width: 97vw;

    }

    .quicklinks img{
        width: 60px;
        height: 60px;
        
    }
    .quicklinks_row1, .quicklinks_row2{
        flex-direction: column;
        justify-content: center;
    }
    .cards{
        flex-wrap: wrap;
    }
    .quicklinksSacco_container {
        flex-wrap: wrap
    }
    .landingphoto {
        width: 100%;
        height: 100%;
        margin: 3px;
    }
    .finances{
        flex-wrap: wrap;
        padding-top: 30px;
        margin-top: 20px;
        margin-left: 3px;
        margin-right: 3px;
    }
}
