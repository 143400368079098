.introarticle{
    text-align: justify;
    padding: 10px;
}

.abouteverything {
    background-color: white;
}

.aboutus {
    text-align: center;
    margin: 3px;
    color:  rgb(12, 96, 102, 0.94);
}

.intphoto{
    background-color: rgb(250,249,248) !important;
}

.accphoto{
    background-color: rgb(249,248,247) !important;
}

.mvv {
    display: flex;
}

.mission {
    display: flex;
    flex-direction: column;
    width: 48vw;

}
.mission:hover,
.vision:hover{
 border-radius: 3px;
 background-color: beige;
 
 transition:2s ease-in-out;
 margin: 1px;
 cursor: pointer;
}
.mission:hover h4,
.vision:hover h4{
    text-decoration: underline;
}


.vision {
    width: 50vw;
}

.abouteverything h4 {
    text-align: center;
    color: rgb(12, 96, 102, 0.94);
    font-weight: 700;
    
} 

.eqphoto{
    background-color: rgb(233,232,232) !important;
}
.cards{
    display: flex;
    gap: 5px;
    margin: 5px;
}
.cards h5{
    text-align: center;
}
@media screen and (max-width: 768px) {
    .mvv {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100vw;
    }

    .mission {
        width: 100vw;
    }

    .vision {
        width: 100vw;
    }

    .cards {
        flex-wrap: wrap;
    }
}