*{
  font-family: "Source sans pro", sans-serif;
}
h1, h2, h3, h4 {
  font-family: 'Montserrat', sans-serif;
}

*::-webkit-scrollbar{
  width: 1.06rem;
}
*::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #486572;
}
.logodiv {
  display: flex;
  justify-content: center;
  background-color: #af8a50;
}

ul {
  list-style-type:circle !important;
}

.logo{
  width: 290px;
  height: 220px;
}

.stuff {
  background-color: #f2f6f8;
}

article{
  text-align: justify;
}


@media screen and (max-width: 768px) {
body {
  padding-bottom: 440px !important;
}
}
