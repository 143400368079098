.terms {
    margin: 30px 10px;
    border: 1px solid grey;
    background-color: beige;
    border-radius: 8px;
    box-shadow: 2px 2px 8px 5px rgb(206, 181, 119);
    position: relative;
}

.terms h3 {
    display: flex;
    justify-content: center;
}

.terms p, article, span {
    margin: 5px;
}