.asset {
    background-color: rgb(12, 96, 102, 0.74);
}

.asset h4, .individual h4, .group h4 {
    text-align:center;
    font-weight: 800;    
}

.asset h5, .individual h5, .group h5 {
    font-weight: 700;
    color:rgb(65, 58, 28);
    text-decoration: underline;
}
.terms {
    background-color: rgb(199, 203, 207);
    text-align: center;
}

.asset article, .individual article, .group article {
    font-weight: 600;
}
.individual:hover{
    
    border-radius: 3px;
}

.individual, .asset, .group {
    margin: 15px;
    padding: 14px;
    text-align: justify;
}

.individual{
    background-color: whitesmoke;
}

.group {
    background-color: rgb(102, 63, 96, 0.74);
}