.bosa {
    margin: 15px;
    padding: 14px;
    text-align: justify;
    background-color: whitesmoke;
    border-radius: 14px;
}

.bosa h4 {
    font-weight: 800;
    text-align: center;
    padding:10px;
}

.bosa h5 {
    font-weight: 700;
}