.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px;
  }
  
  .image-item {
    flex: 1 0 calc(33.333% - 10px);
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .preview-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .preview-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .preview-content {
    position: relative;
    width: 99%;
    max-width:100%;
    height: 98vh;
    padding: 20px;
    /*background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 8000;
  }
  
  .preview-image {
    max-width: 800px;
    max-height: 600px;
    margin-top: 30px;
    height: auto;
    display: block;
    margin-bottom: 20px;
    border-radius: 5px;
    z-index: 8000;
    object-fit: cover;
  }
  
  .close-button {
    font-size: 20px;
    font-weight: bold;
    color:black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
    transition: all 0.3s ease-in-out;
  }

  .next-button,
  .prev-button {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border-radius: 5px;;
    font-size: 20px;
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 50px;
    margin: 0;
    transition: all 0.8s ease-in-out;
  }
  
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #f44336;
  }
  
  .close-button:hover {
    background-color: #ff6659;
  }
  
  .prev-button:hover {
    background-color: #666;
  }
  

  .prev-button {

    left: 0;
    background-color: #ccc;
  }
  
  .next-button {
    right: 0;
    background-color: #ccc;
  }
  
  .next-button:hover {
    background-color: #666;
  }

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
    .image-grid {
        display: flex;
        flex-direction: column;
        margin: 10px;
        margin-bottom: 30px;
    }

    .image-item{
        margin-bottom: 20px;
    }

    .next-button {
      padding-left: 20px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 20px;
      transform: translateY(240px);
      margin-right: 110px;
    }

    .prev-button{
      margin-right: 5px;
      transform: translateY(240px);
      margin-left: 100px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 20px;
      padding-left: 20px;
    }

    .preview-image{
      max-width: 350px;
      max-height: 500px;
    }
}
  
  
  
  
  