.latest_news_container {
    background-color: #f1f1f1;
    
    width: 100vw;
  }
  
  .latest_news_text {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  
  .latest_news_text span {
    margin: 0px;
    background-color: rgb(211, 43, 43);
    padding: 20px;
    z-index: 3000;
  }
  
  .latest_news_content {
    white-space: nowrap;
    overflow: hidden !important;
  }

  @media screen and (max-width: 768px) {
    .latest_news_container{
      overflow: hidden;
    }
  }
  