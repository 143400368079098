.risk, .burial {
    margin: 15px;
    padding: 14px;
}

.risk {
    background-color: rgb(12, 96, 102, 0.74);

}

.burial {
    background-color: rgb(85, 37, 37, 0.71);
}

.risk h4, .burial h4{
    font-weight: 800;
    text-align: center;
}

.intro, .risk article, .burial article {
    font-weight: 700;
}

.intro {
    margin: 12px;
    padding: 10px;
}