.products {
    margin: 15px;
    padding: 14px;
    text-align: justify;
    background-color: rgb(12, 96, 102, 0.74);
    
}

.loan{
    margin: 14px;
    padding: 12px;
    background-color: whitesmoke;
    border-radius: 8px;
}
.loan h4{
    font-weight: 800;
}
.products article {
    padding: 14px;
    font-weight: 700;
}
.products a{
    text-decoration: none;
    color:aliceblue;
}
.products h4 {
    font-weight: 900;
}