form {
    width: 50%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #ddd;
  }

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: whitesmoke;
    height: 80vh;
  }

  .response,
  .loading {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 30px !important;
    height: 80vh !important;

  }

  .p1{
    display: flex;
    justify-content: center;
  }
  
  .input-text,
  .input-email,
  .input-textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-submit {
    width: 100% !important; 
    padding: 10px !important;
    background-color: rgba(12, 51, 102, 0.74) !important;
    color: white !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }
  
  @media (max-width:768px) {
    form {
      width: 100%;
    }
  }
  