.cta {
    display: flex;
    justify-content: space-between;
    background-color: rgba(12, 51, 102, 0.74);
    

}

.time {
    font-weight: 400;
    font-size: 13px;
}
.wh{
    font-weight: 500;
}
.contact{
    font-weight: 500;
}
.right span{
    color: whitesmoke;
}
.left span{
    color: whitesmoke;
}