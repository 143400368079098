 .policy{
    margin: 30px 10px;
    border: 1px solid grey;
    border-radius: 3px;
    background-color: beige;
    box-shadow: 2px 2px 8px 5px rgb(206, 181, 119);
}

.policy h3 {
    display: flex;
    justify-content: center;
}